@import "../../../node_modules/ngx-spinner/animations/ball-spin-clockwise.css";
@import "../../../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../../../node_modules/primeng/resources/primeng.min.css";
@import "../../../node_modules/primeicons/primeicons.css";
@import "../src/assets/argon2/css/nucleo-icons.css";
@import "../src/assets/argon2/css/nucleo-svg.css";
@import "../src/assets/argon2/scss/argon-dashboard.scss";
ng-select {
	border: 0.8px solid #d2d6da;
	padding-left: 12px;
	padding-top: 8px;
	padding-right: 12px;
	padding-bottom: 8px;
	border-radius: 0.5rem;
	.ng-value {
		background-color: #4e14bf1a;
		padding-left: 5px;
		padding-right: 5px;
		border-radius: 2rem;
		margin-right: 5px;
		color: #4e14bf;
	}
	.ng-value-icon {
		margin-right: 0.3rem;
	}
	.ng-dropdown-panel {
		margin-left: -12px !important;
		margin-top: 4px !important;
		background-color: white !important;
		border: 0.8px solid #d2d6da !important;
		border-radius: 0 0 0.8rem 0.8rem;
		overflow: hidden;
		.ng-option {
			padding: 12px 8px;
		}
		.ng-option-marked {
			background-color: #4e14bf1a;
		}
	}
}

.ng-select .ng-clear-wrapper {
	display: none;
}

.sidenav {
	width: 500px !important;
}

.btn {
	border-radius: 2rem !important;
	padding-left: 40px;
	padding-right: 40px;
}

.btn-outline-bg-primary {
	background-color: #ede7f9;
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.custom-badge {
	padding-left: 10px !important;
	padding-top: 4px !important;
	padding-right: 10px !important;
	padding-bottom: 4px !important;
	border-radius: 20px !important;
}

.custom-badge.active-badge {
	background-color: #00ba341a;
	color: #00ba34;
}

.custom-badge.danger-badge {
	background-color: #ff00001a;
	color: #ff0000;
}

.custom-badge.complete-badge {
	background-color: #458cf61a;
	color: #458cf6;
}

.p {
	color: black;
}

.text-black {
	color: black;
}

.btn-primary {
	--bs-btn-disabled-bg: #dddddd !important;
}

.mr-1 {
	margin-right: 1rem;
}

.mr-2 {
	margin-right: 2rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.ml-1 {
	margin-left: 1rem;
}

.ml-2 {
	margin-left: 2rem;
}

.ml-3 {
	margin-left: 3rem;
}

.p {
	margin-bottom: 0px !important;
}

.fs-9 {
	font-size: 9px;
}

.fs-14 {
	font-size: 14px;
}

.fs-16 {
	font-size: 16px;
}

.fs-18 {
	font-size: 18px;
}

.fs-24 {
	font-size: 24px;
}

.fs-28 {
	font-size: 28px;
}

.fs-36 {
	font-size: 36px;
}

.fw-100 {
	font-weight: 100;
}

.fw-200 {
	font-weight: 200;
}

.fw-300 {
	font-weight: 300;
}

.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

.fw-800 {
	font-weight: 800;
}

.navbar-brand {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

th {
	background-color: #f5f7f9 !important;
	color: #222222 !important;
	font-weight: 700 !important;
}

td {
	color: #222222 !important;
}

.table-responsive {
	.form-control:focus {
		box-shadow: none !important;
	}
}

.custom-sm-primary {
	background-color: #4e14bf;
	padding-left: 15px;
	padding-top: 4px;
	padding-right: 15px;
	padding-bottom: 4px;
	border-radius: 20px;
}

.border-radius-50 {
	border-radius: 50%;
}

.vertical-line {
	border-left: 1px solid #dddddd;
	height: var(--dynamic-height, 150px); // Default height is 150px
}

.form-switch .form-check-input {
	height: 1.23rem !important;
}

/* dropdown adjustment  */

@media (min-width: 992px) {
	:not(.main-content) .navbar .dropdown .dropdown-menu {
		top: 0.25rem !important;
	}
}

.dropdown .dropdown-menu.dropdown-menu-end {
	right: 0.75rem !important;
	left: auto !important;
}

/* dropdown adjustment  */

/* to hide aside footer */

.navbar-vertical.navbar-expand-xs .navbar-collapse {
	min-height: 85vh !important;
}

.page-item.active .page-link {
	color: $primary !important;
}

/* exclude some tr from hover */

.table-hover {
	> tbody > tr.no-hover:hover > * {
		--#{$prefix}table-accent-bg: var(--#{$prefix}table-bg);
		color: var(--#{$prefix}table-color);
	}
}

/* prevent sidenav from scroll */

.sidenav {
	overflow-y: hidden !important;
}

/* adjust content space according to sidebar */

@media (min-width: 1200px) {
	.sidenav.fixed-start + .main-content {
		margin-left: 21rem !important;
	}
}

/* remove some nav item padding */

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
}

/* default hr tag style */

hr {
	@extend .bg-secondary !optional;
	@extend .my-3 !optional;
}

.pf-th {
	@extend .text-uppercase !optional;
	@extend .text-secondary !optional;
	@extend .text-xxs !optional;
	@extend .font-weight-bolder !optional;
	@extend .opacity-7 !optional;
}

.pf-td {
	@extend .align-middle !optional;
	@extend .text-sm !optional;
	@extend .py-3 !optional;
	@extend .ps-4 !optional;
}

.pf-text-black {
	color: black !important;
}

/* input group focus */

.input-group:focus-within {
	.input-group-text {
		@extend .border-primary !optional;
	}
}

.pf-cursor-not-allowed {
	cursor: not-allowed;
}

/* zindex & overlay fix */

.modal.fade {
	z-index: 10000000 !important;
	background-color: rgba(0, 0, 0, 0.5) !important;
}

/* tabs */

.nav.nav-pills .nav-item {
	@extend .shadow !optional;
	@extend .mx-2 !optional;
	@extend .my-2 !optional;
	@extend .my-sm-0 !optional;
	@extend .rounded !optional;
}

.nav.nav-pills .nav-link.active {
	background-color: $primary-gradient !important;
	color: $white !important;
}

.pf-badge {
	@extend .d-inline-block !optional;
	@extend .btn !optional;
	@extend .btn-sm !optional;
	@extend .px-2 !optional;
	@extend .py-1 !optional;
	@extend .text-white !optional;
	@extend .shadow-none !optional;
	@extend .m-0 !optional;
	@extend .text-uppercase !optional;
}

.pf-tag {
	@extend .bg-primary !optional;
	@extend .text-uppercase !optional;
	@extend .px-2 !optional;
	@extend .py-1 !optional;
	@extend .text-white !optional;
	@extend .rounded !optional;
	font-size: 0.65rem;
	font-weight: bold;
	i {
		margin-left: 0.125rem;
		cursor: pointer;
	}
}

// Css for custom plan timeline
.timeline {
	position: relative;
}

.timeline-one-side:before {
	left: 1rem;
}

[data-timeline-axis-style="dashed"]:before {
	border-right-style: dashed !important;
}

.timeline:before {
	position: absolute;
	top: 0;
	left: 1rem;
	height: 100%;
	content: "";
	border-right: 2px solid #e9ecef;
}

.timeline-block:first-child {
	margin-top: 0;
}

.timeline-block {
	position: relative;
	margin: 2em 0;
}

.timeline-one-side .timeline-step {
	left: 1rem;
}

.timeline-step {
	font-size: 1rem;
	font-weight: 600;
	position: absolute;
	z-index: 1;
	left: 0;
	display: inline-flex;
	width: 33px;
	height: 33px;
	transform: translateX(-50%);
	text-align: center;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}

.timeline-one-side .timeline-content {
	width: auto;
}

.timeline-content {
	position: relative;
	position: relative;
	top: -6px;
	margin-left: 60px;
	padding-top: 0.5rem;
}

.timeline-content:after {
	display: table;
	clear: both;
	content: "";
}

.timeline-badge-success {
	color: #1aae6f;
	background-color: #93e7c3;
}

.timeline-badge-info {
	color: #03acca;
	background-color: #88e6f7;
}

.timeline-badge-warning {
	color: #ff3709;
	background-color: #fec9bd;
}

.timeline-badge-end {
	color: #fb6340;
	background-color: #fec9bd;
}

.text-purple {
	color: #4e14bf;
}

.bg-cyan {
	background: #0ae1ef !important;
}

// .bg-cyans {
//     background: #4E00FF !important;
// }
.text-cyan {
	color: #0ae1ef !important;
}

.font-weight-600 {
	font-weight: 600 !important;
}

.font-weight-700 {
	font-weight: 700 !important;
}

@media (min-width: 768px) {
	.title {
		font-size: 2rem;
	}
	.sub-title {
		font-size: 1rem;
	}
}

@media (max-width: 767px) {
	.title {
		font-size: 1.5rem;
	}
	.sub-title {
		font-size: 0.9rem;
	}
}

@media (max-width: 399px) {
	.title {
		font-size: 1rem;
	}
	.sub-title {
		font-size: 0.8rem;
	}
}

:root {
	overflow-x: hidden !important;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
	background-position: center right;
	background-size: 18px;
}

/* PrimeNG */
.p-column-filter-menu-button {
	width: 15px;
	height: 15px;
}
.p-icon {
	width: 12px;
	height: 12px;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
	margin-left: 0.1rem;
}
.p-paginator-current {
	margin-left: auto;
}
.p-paginator .p-dropdown {
	height: auto;
}
.p-paginator .p-paginator-pages .p-paginator-page {
	margin: 0.143rem;
}

/* scroll-bar */

/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(78, 20, 191, 0.7);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #4e14bf;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #4e14bf;
}
