.text-dark {
    color: #000000 !important;
}

.right-0 {
    right: 0,
}

.top-0 {
    top: 0,
}